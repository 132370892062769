import React, { useState, useEffect } from 'react'
import { Image, Heading, Text, Flex, Box} from '@pancakeswap/uikit'
import styled, { keyframes } from 'styled-components'
import Page from 'components/Layout/Page'
import { useTranslation } from '@pancakeswap/localization'
import PageHeader from 'components/PageHeader'
import Script from 'next/script'
import { useRouter } from 'next/router'
import Head from 'next/head'

const Store: React.FC = () => {

  const { t } = useTranslation()
  const router = useRouter();
  const [Js, setJs] = useState<React.ReactNode | null>(null)

  const handleComplete = () => {
    setJs(null)
    setJs(
      <Head>
        <script
          id="my-Shop-S1"
        >
            {`var spread_shop_config = {
              shopName: 'bitnoumerch',
              locale: 'us_US',
              prefix: 'https://bitnoumerch.myspreadshop.com',
              baseId: 'myShop'
            };`}
        </script>
        <script
          id="my-Shop-S2"
          type="text/javascript"
          src="https://bitnoumerch.myspreadshop.com/shopfiles/shopclient/shopclient.nocache.js"
        />
      </Head>
    )
  }

  useEffect(() => {
    setJs(
      <Head>
        <script
          id="my-Shop-S1"
        >
            {`var spread_shop_config = {
              shopName: 'bitnoumerch',
              locale: 'us_US',
              prefix: 'https://bitnoumerch.myspreadshop.com',
              baseId: 'myShop'
            };`}
        </script>
        <script
          id="my-Shop-S2"
          type="text/javascript"
          src="https://bitnoumerch.myspreadshop.com/shopfiles/shopclient/shopclient.nocache.js"
        />
      </Head>
    )
    router.events.on('routeChangeComplete', handleComplete)
  }, [router.events])

  return (
    <>
      {/* <PageHeader>
        <Heading as="h1" scale="xxl" color="secondary" mb="24px">
          {t('Store')}
        </Heading>
      </PageHeader> */}
      <Page>
        <div id="myShop" />
        {Js}
      </Page>      
    </>
  )
}

export default Store
